import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Images
import Plane from "../assets/travel-plane.svg"

// Icons
import ResponsiveIcon from "../assets/icons/responsive.svg"
import BubblesIcon from "../assets/icons/bubbles.svg"
import ClickIcon from "../assets/icons/one-click.svg"
import ShareIcon from "../assets/icons/share.svg"
import SecureIcon from "../assets/icons/safe-check.svg"
import PercentageIcon from "../assets/icons/percent-e.svg"
import ClipIcon from "../assets/icons/clip.svg"
import ShopIcon from "../assets/icons/shop.svg"
import UserIcon from "../assets/icons/user.svg"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Styles
import "../styles/pages/online-payment-services-travel-agencies.scss"

// Components
import FeaturesSection from "../components/features-section"
import SectionTitle from "../components/section-title"
import GetStartedSection from "../components/get-started-section"
import ThreeSteps from "../components/three-linked-steps"

const HeroSection = ({ image }) => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-6 travel-hero">
      <div className="container">
        <div className="columns is-variable is-8">
          <div className="column is-5">
            <h1 className="title main-title has-text-white is-spaced ">
              {t("Online Payments for Travel Agencies")}
            </h1>
            <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
              {t(
                "Twispay provides travel agencies with a set of low-cost online payment solutions designed to streamline transactions and optimize user experience. Travel agencies get to focus on the traveller while we take care of the rest."
              )}
            </h2>
          </div>
          <div className="column">
            <div className="map-wrapper">
              <div className="plane-wrapper">
                <img src={Plane} alt="Twispay Plane" />
              </div>
              <span className="side side1" />
              <span className="side side2" />
              <span className="side side3" />
              <div
                style={{
                  backgroundImage: `url(${image})`,
                }}
                className="display-wrapper map"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const DesignedSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <ResponsiveIcon />,
      title: t("Impeccable on Every Device"),
      description: t(
        "The payment solution renders seamlessly on desktops, Android and iOS devices"
      ),
    },
    {
      icon: <BubblesIcon />,
      title: t("Superior User Experience"),
      description: t(
        "Your checkout page will match the look and feel of your brand"
      ),
    },
    {
      icon: <ClickIcon />,
      title: t("One-Touch Payments"),
      description: t(
        "Blazing-fast online payments and seamless checkout experiences"
      ),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Designed for Travellers")}
          subtitle={t(
            "Long gone are the days of time-consuming financial transactions. One click or one touch and everyone can get back to that dream vacation."
          )}
        />
        <div className="columns  has-text-centered ">
          {items.map(item => (
            <div key={item.title} className="column">
              <span className="icon is-larger has-margin-bottom-5 has-text-primary">
                {item.icon}
              </span>
              <h4 className="title is-4 ">{item.title}</h4>
              <p className="subtitle is-5 has-text-secondary">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const IntegrationSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <ShareIcon />,
      title: t("Easy Payment-Link Sharing"),
      description: t(
        "Either on your website, in a message, or through your social media pages"
      ),
    },
    {
      icon: <SecureIcon />,
      title: t("Completely Secure"),
      description: t(
        "You and your client's data and funds are always safeguarded"
      ),
    },
    {
      icon: <PercentageIcon />,
      title: t("Lowest Fees"),
      description: t("Save up on traditional banking commissions"),
    },
  ]
  const steps = [
    {
      icon: <ClipIcon />,
      tooltip: t("You Generate a Payment Link in 30 seconds flat"),
    },
    {
      icon: <ShopIcon />,
      tooltip: t("You insert the link on your website or on social media"),
    },
    {
      icon: <UserIcon />,
      tooltip: t("Your Customers click the link and make the payment"),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Easy Integration with Payment Links")}
          subtitle={t(
            "Do your clients have the habit of requesting extra services, rendering fixed pricing virtually impossible? Twispay has got you covered"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column is-one-third">
            {items.map((item, i) => (
              <div
                key={item.title}
                className={`is-flex  ${
                  i < items.length - 1 ? "has-margin-bottom-6" : ""
                }`}
              >
                <span className="icon is-medium has-margin-right-4 has-text-primary">
                  {item.icon}
                </span>
                <div>
                  <h4 className="title is-5 ">{item.title}</h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="column">
            <ThreeSteps steps={steps} color="is-red" />
          </div>
        </div>
      </div>
    </section>
  )
}

const ECommercePage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-red-violet"
      pageName="online-payment-services-travel-agencies"
    >
      <SEO title={t("Online Payments for Travel Agencies")} />
      <HeroSection image={images.map.publicURL} />
      <DesignedSection />
      <IntegrationSection />
      <FeaturesSection
        title={t("A Seamless Experience")}
        subtitle={t(
          "Twispay's online payment solutions are engineered to easily blend in with your existing designs and future developments"
        )}
        images={images}
      />

      <GetStartedSection
        title={t("Don't Settle for JUST Payment Services")}
        subtitle={t("Fine-tune your online payment experience with Twispay")}
      />
    </Layout>
  )
}

export const images = graphql`
  query {
    map: file(relativePath: { eq: "travel-map.png" }) {
      publicURL
    }
    buildinPhone: file(relativePath: { eq: "home-builtin-phone.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secureImage: file(relativePath: { eq: "payment-modules-secure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutImage: file(relativePath: { eq: "checkout.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    checkoutDarkImage: file(relativePath: { eq: "checkout_dark.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shopImage: file(relativePath: { eq: "ecommerce-shop.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    dashboardImage: file(relativePath: { eq: "ecommerce-dashboard.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ECommercePage
