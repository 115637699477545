import React from "react"
import Img from "gatsby-image"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import BubblesIcon from "../assets/icons/bubbles.svg"
import BlockIcon from "../assets/icons/block.svg"
import OneClickIcon from "../assets/icons/one-click.svg"
import PaintIcon from "../assets/icons/paint.svg"
import SafeIcon from "../assets/icons/safe.svg"
import ReloadIcon from "../assets/icons/reload.svg"

// Styles
import "../styles/components/features-section.scss"

// Components
import SectionTitle from "./section-title"

// In-page Components
const FeatureItem = ({ icon, title, subtitle }) => (
  <div className="feature-wrapper is-flex">
    <span className="icon is-medium has-text-primary has-margin-right-5">
      {icon}
    </span>
    <div className="text-wrapper ">
      <h4 className="title is-5 ">{title}</h4>
      <p className="subtitle is-6 has-text-secondary">{subtitle}</p>
    </div>
  </div>
)

const FeaturesSection = ({ title, subtitle, images, invertImages }) => {
  const { t } = useTranslation()
  const checkoutFluid = images.checkoutImage.childImageSharp.fluid
  const checkoutDarkFluid = images.checkoutDarkImage.childImageSharp.fluid

  const features = [
    {
      icon: <OneClickIcon />,
      title: t("One-Click Payments"),
      subtitle: t("Instant purchases, with no need to reenter card details"),
    },
    {
      icon: <PaintIcon />,
      title: t("Tailored Checkout"),
      subtitle: t(
        "Your logo, your brand colors, your unique user experience (UX)"
      ),
    },

    {
      icon: <SafeIcon />,
      title: t("Safe & Secure"),
      subtitle: t("PCI-DSS compliance, 24/7 fraud monitoring"),
    },
    {
      icon: <BubblesIcon />,
      title: t("Quality Focused"),
      subtitle: t(
        "Seamless UX and continuous conversion rate optimization (CRO)"
      ),
    },

    {
      icon: <ReloadIcon />,
      title: t("Automated Subscriptions"),
      subtitle: t(
        "Point-and-click recurring payments and service subscriptions"
      ),
    },
    {
      icon: <BlockIcon />,
      title: t("Innovative Dashboard"),
      subtitle: t(
        "Access to multiple providers and banks, with a single integration"
      ),
    },
  ]
  return (
    <section className="section features-section has-margin-bottom-6 has-margin-bottom-0-mobile">
      <div className="container">
        <SectionTitle
          title={title}
          subtitle={subtitle}
          className="has-margin-bottom-7 has-margin-bottom-6-mobile"
        />

        <div className="columns">
          <div className="column is-two-fifths ">
            <div className="images-wrapper">
              <div className="is-perspective image-wrapper">
                <Img
                  className="checkout-img"
                  fluid={invertImages ? checkoutDarkFluid : checkoutFluid}
                />
              </div>
              <div className="is-perspective image-wrapper is-right">
                <Img
                  className="checkout-dark-img"
                  fluid={invertImages ? checkoutFluid : checkoutDarkFluid}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <div className="columns is-multiline is-variable is-8">
                  {features.map(feature => (
                    <div
                      key={feature.title}
                      className="column is-half has-margin-bottom-5"
                    >
                      <FeatureItem
                        title={feature.title}
                        subtitle={feature.subtitle}
                        icon={feature.icon}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
