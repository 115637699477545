import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-intl"

// Hooks
import useTranslation from "../hooks/useTranslation"

const SectionTitle = ({ title, subtitle }) => {
  const { t } = useTranslation()
  return (
    <section className="section has-margin-bottom-6">
      <div className="container has-text-centered">
        <h4 className="title is-spaced">{title}</h4>
        <p className="subtitle">{subtitle}</p>
        <Link to="/price-calculator" className="button is-medium is-success">
          {t("Get Started")}
        </Link>
      </div>
    </section>
  )
}
SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}
export default SectionTitle
