import React from "react"
import PropTypes from "prop-types"

// Styles
import "../styles/components/three-linked-steps.scss"

const ThreeSteps = ({ steps, color }) => {
  return (
    <div className="three-linked-steps-wrapper is-flex is-centered-centered">
      <div className={`${color}`}>
        {steps.map((step, i) => (
          <div
            key={step.tooltip}
            className={`step is-flex is-vcentered ${
              i !== 2 ? "has-margin-bottom-7" : ""
            }`}
          >
            <span
              className={`icon is-large has-background-light  ${
                i === 1
                  ? "has-margin-left-5 has-text-white"
                  : "has-margin-right-5 has-text-secondary "
              }`}
            >
              {step.icon}
            </span>
            <p className="is-size-7">
              <span className="step-number has-background-white has-text-default has-margin-right-2">
                {i + 1}
              </span>
              {step.tooltip}
            </p>
            {i !== 2 && (
              <div className="connecting-line has-background-secondary" />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
ThreeSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
}
export default ThreeSteps
